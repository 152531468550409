import React, { useEffect, useState } from 'react';
// import useDevice from './hooks/useDevice';
// eslint-disable-next-line no-unused-vars
import {
	MediaId,
	PhotoMedia,
	photoSectionIdFromUrlSlug,
	PHOTO_DEFINITIONS,
	PHOTO_SECTIONS,
	ValidationError,
} from './types';
// import { ReactComponent as InfoIcon } from './assets/imgs/i-info.svg';
import { ReactComponent as ViewIcon } from './assets/imgs/i-view.svg';
import Modal from './modal';
import ContinueButton from './continueButton';
import { getHelpModalContents, runValidations } from './helpers';
import Loading from './loading';
import ValidationWarning from './validationWarnings';
import './styles.scss';

function ImageUploadThumbnail({
	photo,
	onChange,
	onPreviewClick,
	onHelpClick,
}: {
	photo: PhotoMedia;
	onChange?: (
		mediaId: MediaId,
		event: React.ChangeEvent<HTMLInputElement>,
		sectionId?: number,
	) => Promise<void>;
	onPreviewClick?: any;
	onHelpClick?: any;
}): JSX.Element {
	const photoDefinition = PHOTO_DEFINITIONS[photo.id];

	const sendFile = async (
		e: React.ChangeEvent<HTMLInputElement>,
	): Promise<void> => {
		if (onChange) {
			await onChange(photo.id, e, photoDefinition.sectionId);
		}
	};

	if (photo.url) {
		return (
			<div className="image-preview-thumbnail">
				<img
					src={photo.url}
					alt={photoDefinition.title}
					onClick={() => onPreviewClick(photo)}
				/>
				<div className="preview-label">{photoDefinition.shortTitle}</div>
			</div>
		);
	}
	const htmlId = `photo-${photo.id}`;

	return (
		<div className="image-upload-thumbnail">
			<label htmlFor={htmlId} className="custom-file-upload">
				{photoDefinition?.placeholder}
				{photoDefinition.title}
			</label>
			<div className="ayuda" onClick={() => onHelpClick(photo.id)}>
				<ViewIcon />
				<span>Ejemplo</span>
			</div>
			<input
				id={htmlId}
				type="file"
				accept="image/*"
				capture="environment"
				onChange={sendFile}
			/>
		</div>
	);
}

function FullScreenPreview({
	url,
	onRemove,
	onExit,
}: {
	url: string;
	onRemove: (url: string) => Promise<any>;
	onExit: any;
}): JSX.Element {
	const actionToRemove = async () => {
		await onRemove(url);
	};

	return (
		<div className="full-screen-preview">
			<img className="preview-photo" src={url} alt="Photo preview" />
			<div className="action-buttons">
				<button className="remove" onClick={actionToRemove}>
					Eliminar
				</button>
				<button className="button button-block" onClick={onExit}>
					Volver
				</button>
			</div>
		</div>
	);
}

interface GalleryProps {
	id: string;
	handleRetrieve: () => Promise<any>;
	handleUpload: (
		prefix: string,
		uploadType: 'STREAM' | 'BASE64',
		fileName: string,
		fileExtension: string,
		fileObj: any,
	) => Promise<string>;
	handleRemove: (url: string) => Promise<void>;
	validationURL: string;
}

const ImageGallery = ({
	id,
	validationURL,
	handleRetrieve,
	handleUpload,
	handleRemove,
}: GalleryProps): JSX.Element => {
	const [showSectionHelp, setShowSectionHelp] = useState(false);
	const [helpModalId, setHelpModalId] = useState<MediaId | null>(null);
	const [loading, setLoading] = useState(false);
	const [
		validationError,
		setValidationError,
	] = useState<ValidationError | null>(null);
	const [previewPhoto, setPreviewPhoto] = useState<PhotoMedia | null>(null);
	const [initRetrieve, setInitRetrieve] = useState(true);
	const [photos, setPhotos] = useState<any[]>([]);
	const photoSectionId = photoSectionIdFromUrlSlug(id);
	const photoSection = PHOTO_SECTIONS[photoSectionId];

	const initialPhotoState = (id: MediaId, url?: string) => {
		return { id: id, url: url ?? undefined };
	};

	const toggleSectionHelp = () => {
		setShowSectionHelp(!showSectionHelp);
	};

	const handleWarningButton = (): void => {
		setValidationError(null);
	};

	const clearHelpId = () => {
		setHelpModalId(null);
	};

	const onRetrieve = async (): Promise<void> => {
		const mediaData = await handleRetrieve();

		const state: { [key in MediaId]?: PhotoMedia } = {};

		(Object.keys(MediaId) as MediaId[]).forEach((mediaId) => {
			if (Array.from(mediaData).length > 0) {
				const objData: any = Array.from(mediaData).filter(
					(f: any) =>
						f.name !== null &&
						f.name !== undefined &&
						String(f.name).indexOf(mediaId) > 0,
				)[0];

				if (objData && String(objData.url).length > 0) {
					state[mediaId] = initialPhotoState(mediaId, objData.url);
				} else {
					state[mediaId] = initialPhotoState(mediaId);
				}
			} else {
				state[mediaId] = initialPhotoState(mediaId);
			}
		});

		// setPhotoData(state as { [key in MediaId]: PhotoMedia });
		const castData = state as { [key in MediaId]: PhotoMedia };
		setPhotos(photoSection.photoIds.map((mediaId) => castData[mediaId]));
	};

	const onChange = async (
		mediaId: MediaId,
		event: React.ChangeEvent<HTMLInputElement>,
		sectionId?: number,
	) => {
		if (!event.target.files) {
			return;
		}

		event.persist();

		setLoading(true);
		try {
			const sectionPrefix = sectionId ? sectionId.toString() : '';
			const urlResult = await handleUpload(
				sectionPrefix,
				'STREAM',
				mediaId,
				'',
				event,
			);

			const [error, mediaType, data] = await runValidations(
				validationURL,
				sectionPrefix,
				mediaId,
				urlResult,
				event.target.files[0],
				handleUpload,
				handleRemove,
			);

			// eslint-disable-next-line no-console
			console.log(
				'RUN-VALIDATION => Error: ',
				error,
				' | Type: ',
				mediaType,
				' | Data: ',
				data,
			);

			if (!error) {
				const currentPhotos = { ...photos };
				currentPhotos[mediaId as MediaId] = {
					...currentPhotos[mediaId as MediaId],
					url: urlResult,
					data: data,
				};

				// eslint-disable-next-line no-console
				console.log('UPDATE-FROM-VALIDATION :: ', currentPhotos);
			} else {
				await handleRemove(urlResult);
				setValidationError(error);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
			setInitRetrieve(true);
		}
	};

	const removePhoto = async (url?: string) => {
		if (url) {
			await handleRemove(url);
			setInitRetrieve(true);
		}
		setPreviewPhoto(null);
	};

	const helpContents = getHelpModalContents(helpModalId);

	useEffect(() => {
		async function fetchData() {
			if (initRetrieve) {
				await onRetrieve();
				setInitRetrieve(false);
			}
		}
		fetchData();
	}, [initRetrieve]);

	return (
		<div className="photo-section-screen">
			{showSectionHelp && (
				<Modal onBackdropClick={toggleSectionHelp}>
					<h2>{photoSection.title}</h2>
					{photoSection.helpModal}
					<ContinueButton onClick={toggleSectionHelp}>Entendido</ContinueButton>
				</Modal>
			)}
			{helpModalId && helpContents && (
				<Modal onBackdropClick={clearHelpId} className="photo-help-modal">
					<h2>{helpContents.title}</h2>
					{helpContents.imgUrl && (
						<img
							src={helpContents?.imgUrl}
							alt={helpContents.title}
							height={200}
						/>
					)}
					{helpContents.paragraph}
					<ContinueButton onClick={clearHelpId}>Entendido</ContinueButton>
				</Modal>
			)}

			{loading && <Loading />}
			{validationError && (
				<ValidationWarning
					validationError={validationError}
					handleWarningButton={handleWarningButton}
				/>
			)}

			<div className="container">
				{/* <h3 className="lead">
					<span>{PHOTO_SECTIONS[photoSectionId].title}</span>
					<InfoIcon onClick={toggleSectionHelp} />
				</h3> */}
				<div className="photos-scrollable-area">
					<div className="photos-container">
						{photos
							.filter((photo) => PHOTO_DEFINITIONS[photo.id].isVisible)
							.map((photo) => (
								<ImageUploadThumbnail
									key={photo.id}
									photo={photo}
									onChange={onChange}
									onPreviewClick={setPreviewPhoto}
									onHelpClick={setHelpModalId}
								/>
							))}
					</div>
				</div>
			</div>

			{previewPhoto?.url && (
				<FullScreenPreview
					url={previewPhoto.url}
					onRemove={removePhoto}
					onExit={() => setPreviewPhoto(null)}
				/>
			)}
		</div>
	);
};

export default ImageGallery;
