// Menu Section Icons
import { ReactComponent as VideoIcon } from './../assets/imgs/photo-menu/i-360video.svg';
import { ReactComponent as DocumentIcon } from './../assets/imgs/photo-menu/i-documento.svg';
import { ReactComponent as ExteriorIcon } from './../assets/imgs/photo-menu/i-exterior.svg';
import { ReactComponent as InteriorIcon } from './../assets/imgs/photo-menu/i-interior.svg';

// Exterior Placeholders
import { ReactComponent as Plate } from './../assets/imgs/photo-placeholders/exterior/plate.svg';
import { ReactComponent as Front } from './../assets/imgs/photo-placeholders/exterior/front.svg';
import { ReactComponent as Left } from './../assets/imgs/photo-placeholders/exterior/left.svg';
import { ReactComponent as Right } from './../assets/imgs/photo-placeholders/exterior/right.svg';
import { ReactComponent as Back } from './../assets/imgs/photo-placeholders/exterior/back.svg';
import { ReactComponent as Panoramic } from './../assets/imgs/photo-placeholders/exterior/panoramic.svg';

// Documents Placeholders
import { ReactComponent as Scan } from './../assets/imgs/photo-placeholders/documents/i-scan.svg';
import { ReactComponent as Doc } from './../assets/imgs/photo-placeholders/documents/document.svg';
import { ReactComponent as Riteve } from './../assets/imgs/photo-placeholders/documents/i-riteve.svg';
import { ReactComponent as IdCard } from './../assets/imgs/photo-placeholders/documents/i-idcard.svg';

// Interior Placeholders
import { ReactComponent as InteriorPlaceholder } from './../assets/imgs/photo-placeholders/interior/i-interior-1.svg';
import { ReactComponent as Vin } from './../assets/imgs/photo-placeholders/interior/i-vin.svg';
import { ReactComponent as Speedometer } from './../assets/imgs/photo-placeholders/interior/i-speedometer.svg';

import React, { ReactElement } from 'react';

export interface IExteriorData {
	id: number;
	zoneName: string;
	s3Url: string;
	webHook: string;
}

export enum MediaSection {
	VEHICLE_EXTERIOR = 40,
	VEHICLE_INTERIOR = 41,
	VEHICLE_VIDEO = 42,
	DOCUMENTS = 43,
}

export enum MediaId {
	VEHICLE_EXTERIOR_PLATE = 'VEHICLE_EXTERIOR_PLATE',
	VEHICLE_EXTERIOR_FRONT = 'VEHICLE_EXTERIOR_FRONT',
	VEHICLE_EXTERIOR_RIGHT = 'VEHICLE_EXTERIOR_RIGHT',
	VEHICLE_EXTERIOR_BACK = 'VEHICLE_EXTERIOR_BACK',
	VEHICLE_EXTERIOR_LEFT = 'VEHICLE_EXTERIOR_LEFT',

	VEHICLE_INTERIOR_ODOMETER = 'VEHICLE_INTERIOR_ODOMETER',
	VEHICLE_INTERIOR_VIN = 'VEHICLE_INTERIOR_VIN',
	VEHICLE_INTERIOR_1 = 'VEHICLE_INTERIOR_1',
	VEHICLE_INTERIOR_2 = 'VEHICLE_INTERIOR_2',

	VEHICLE_VIDEO = 'VEHICLE_VIDEO',

	DOCUMENTS_VEHICLE_PROPERTY_REGISTRY = 'DOCUMENTS_VEHICLE_PROPERTY_REGISTRY',
	DOCUMENTS_CEDULA = 'DOCUMENTS_CEDULA',
	DOCUMENTS_CEDULA_CRC = 'DOCUMENTS_CEDULA_CRC',
	DOCUMENTS_DRIVERS_LICENSE = 'DOCUMENTS_DRIVERS_LICENSE',
	INSURED_VEHICLE_RTV_CRC = 'INSURED_VEHICLE_RTV_CRC',
	INSURED_VEHICLE_LICENSE_CRC = 'INSURED_VEHICLE_LICENSE_CRC',
	INSURED_VEHICLE_PANORAMIC_CRC = 'INSURED_VEHICLE_PANORAMIC_CRC',
	INSURED_VEHICLE_DOCUMENT_CRC = 'INSURED_VEHICLE_DOCUMENT_CRC',
	INSURED_CUSTOM_1_CRC = 'INSURED_CUSTOM_1_CRC',
	INSURED_CUSTOM_2_CRC = 'INSURED_CUSTOM_2_CRC',
	INSURED_VEHICLE_LICENSE_PAN = 'INSURED_VEHICLE_LICENSE_PAN',
	INSURED_VEHICLE_PANORAMIC_PAN = 'INSURED_VEHICLE_PANORAMIC_PAN',
	INSURED_VEHICLE_DOCUMENT_PAN = 'INSURED_VEHICLE_DOCUMENT_PAN',
	INSURED_CUSTOM_1_PAN = 'INSURED_CUSTOM_1_PAN',
	INSURED_CUSTOM_2_PAN = 'INSURED_CUSTOM_2_PAN',
}

export enum ValidationError {
	IS_BLURRY = 'IS_BLURRY',
	NO_DOCUMENT = 'NO_DOCUMENT',
	NO_VEHICLE = 'NO_VEHICLE',
	NO_PLATE = 'NO_PLATE',
	NO_VIN = 'NO_VIN',
	NO_ODOMETER = 'NO_ODOMETER',
}

export interface PhotoMedia {
	id: MediaId;
	url?: string | undefined;
	base64?: string | undefined;
	data?: any | undefined;
}

export interface PhotoDefinition {
	placeholder: any;
	title: string;
	sectionId?: number;
	shortTitle: string;
	exampleImageUrl: string;
	isVisible: boolean;
}

export const PHOTO_DEFINITIONS: { [key in MediaId]: PhotoDefinition } = {
	[MediaId.VEHICLE_EXTERIOR_PLATE]: {
		placeholder: <Plate className="theme-svg-icon" />,
		title: 'Placa',
		shortTitle: 'Placa',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_EXTERIOR_FRONT]: {
		placeholder: <Front className="theme-svg-icon" height={50} />,
		title: 'Frontal',
		shortTitle: 'Frontal',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_EXTERIOR_RIGHT]: {
		placeholder: <Right className="theme-svg-icon" />,
		title: 'Lateral derecho',
		shortTitle: 'Lateral derecho',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_EXTERIOR_BACK]: {
		placeholder: <Back className="theme-svg-icon" />,
		title: 'Trasera',
		shortTitle: 'Trasera',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_EXTERIOR_LEFT]: {
		placeholder: <Left className="theme-svg-icon" />,
		title: 'Lateral izquierdo',
		shortTitle: 'Lateral izquierdo',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_INTERIOR_ODOMETER]: {
		placeholder: <Speedometer className="theme-svg-icon" />,
		title: 'Odómetro',
		shortTitle: 'Odómetro',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_INTERIOR_VIN]: {
		placeholder: <Vin className="theme-svg-icon" />,
		title: 'VIN (Número de Chasis)',
		shortTitle: 'VIN',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_INTERIOR_1]: {
		placeholder: <InteriorPlaceholder className="theme-svg-icon" />,
		title: 'Interior 1',
		shortTitle: 'Interior 1',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.VEHICLE_INTERIOR_2]: {
		placeholder: <InteriorPlaceholder className="theme-svg-icon" />,
		title: 'Interior 2',
		shortTitle: 'Interior 2',
		exampleImageUrl: '',
		isVisible: true,
	},

	[MediaId.VEHICLE_VIDEO]: {
		placeholder: <VideoIcon className="theme-svg-icon" />,
		title: 'Video',
		shortTitle: 'Video',
		exampleImageUrl: '',
		isVisible: true,
	},

	[MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Registro de propiedad vehicular',
		shortTitle: 'RUV',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.DOCUMENTS_CEDULA]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Cédula',
		shortTitle: 'Cédula',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.DOCUMENTS_CEDULA_CRC]: {
		placeholder: <IdCard className="theme-svg-icon" />,
		title: 'Documento de Identificación',
		shortTitle: 'Identificación',
		exampleImageUrl: '',
		sectionId: 3,
		isVisible: true,
	},
	[MediaId.DOCUMENTS_DRIVERS_LICENSE]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Licencia de Conducir',
		shortTitle: 'Licencia',
		exampleImageUrl: '',
		isVisible: true,
	},
	[MediaId.INSURED_VEHICLE_DOCUMENT_PAN]: {
		placeholder: <Doc className="theme-svg-icon" />,
		title: 'Derecho de Circulación',
		shortTitle: 'Derecho Circulación',
		exampleImageUrl: '',
		sectionId: 3,
		isVisible: true,
	},
	[MediaId.INSURED_VEHICLE_PANORAMIC_PAN]: {
		placeholder: <Panoramic className="theme-svg-icon" />,
		title: 'Panorámica Incidente',
		shortTitle: 'Panorámica',
		exampleImageUrl: '',
		sectionId: 1,
		isVisible: true,
	},
	[MediaId.INSURED_VEHICLE_LICENSE_PAN]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Licencia de Conducir',
		shortTitle: 'Licencia',
		exampleImageUrl: '',
		sectionId: 3,
		isVisible: true,
	},
	[MediaId.INSURED_CUSTOM_1_PAN]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Otra Imagen 1',
		shortTitle: 'Otra Imagen 1',
		exampleImageUrl: '',
		sectionId: 6,
		isVisible: true,
	},
	[MediaId.INSURED_CUSTOM_2_PAN]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Otra Imagen 2',
		shortTitle: 'Otra Imagen 2',
		exampleImageUrl: '',
		sectionId: 6,
		isVisible: true,
	},
	[MediaId.INSURED_VEHICLE_DOCUMENT_CRC]: {
		placeholder: <Doc className="theme-svg-icon" />,
		title: 'Derecho de Circulación',
		shortTitle: 'Circulación',
		exampleImageUrl: '',
		sectionId: 3,
		isVisible: true,
	},
	[MediaId.INSURED_VEHICLE_PANORAMIC_CRC]: {
		placeholder: <Panoramic className="theme-svg-icon" />,
		title: 'Panorámica Incidente',
		shortTitle: 'Panorámica',
		exampleImageUrl: '',
		sectionId: 1,
		isVisible: true,
	},
	[MediaId.INSURED_VEHICLE_LICENSE_CRC]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Licencia de conducir',
		shortTitle: 'Licencia',
		exampleImageUrl: '',
		sectionId: 3,
		isVisible: true,
	},
	[MediaId.INSURED_CUSTOM_1_CRC]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Otra Imagen 1',
		shortTitle: 'Otra Imagen 1',
		exampleImageUrl: '',
		sectionId: 6,
		isVisible: true,
	},
	[MediaId.INSURED_CUSTOM_2_CRC]: {
		placeholder: <Scan className="theme-svg-icon" />,
		title: 'Otra Imagen 2',
		shortTitle: 'Otra Imagen 2',
		exampleImageUrl: '',
		sectionId: 6,
		isVisible: true,
	},
	[MediaId.INSURED_VEHICLE_RTV_CRC]: {
		placeholder: <Riteve className="theme-svg-icon" />,
		title: 'RITEVE',
		shortTitle: 'RITEVE',
		exampleImageUrl: '',
		sectionId: 3,
		isVisible: true,
	},
};

export enum PhotoSectionId {
	EXTERIOR = 'EXTERIOR',
	INTERIOR = 'INTERIOR',
	VIDEO = 'VIDEO',
	DOCUMENTS = 'DOCUMENTS',
	COINSPECT_CRC = 'COINSPECT_CRC',
	COINSPECT_PAN = 'COINSPECT_PAN',
}

export function photoSectionIdFromUrlSlug(id: string): PhotoSectionId {
	return id.toUpperCase() as PhotoSectionId;
}

interface PhotoSectionDefinition {
	icon: ReactElement;
	title: string;
	photoIds: MediaId[];
	helpModal: ReactElement;
}

export const PHOTO_SECTIONS: {
	[key in PhotoSectionId]: PhotoSectionDefinition;
} = {
	[PhotoSectionId.EXTERIOR]: {
		icon: <ExteriorIcon className="theme-svg-icon" width={30} height={30} />,
		title: 'Exterior del vehículo',
		photoIds: [
			MediaId.VEHICLE_EXTERIOR_PLATE,
			MediaId.VEHICLE_EXTERIOR_FRONT,
			MediaId.VEHICLE_EXTERIOR_RIGHT,
			MediaId.VEHICLE_EXTERIOR_BACK,
			MediaId.VEHICLE_EXTERIOR_LEFT,
		],
		helpModal: (
			<div>
				<p>
					Toma las siguientes consideraciones a la hora de tomar las
					fotografías:
				</p>
				<ul>
					<li>
						Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
					</li>
					<li>Debe verse el vehículo completo.</li>
					<li>El lugar donde realizas la inspección debe estar iluminado.</li>
				</ul>
			</div>
		),
	},
	[PhotoSectionId.INTERIOR]: {
		icon: <InteriorIcon className="theme-svg-icon" width={30} height={30} />,
		title: 'Interior del vehículo',
		photoIds: [
			MediaId.VEHICLE_INTERIOR_ODOMETER,
			MediaId.VEHICLE_INTERIOR_VIN,
			MediaId.VEHICLE_INTERIOR_1,
			MediaId.VEHICLE_INTERIOR_2,
		],
		helpModal: (
			<div>
				<p>
					Toma las siguientes consideraciones a la hora de tomar las
					fotografías:
				</p>
				<ul>
					<li>Buena ilumincación.</li>
				</ul>
			</div>
		),
	},
	[PhotoSectionId.DOCUMENTS]: {
		icon: <DocumentIcon className="theme-svg-icon" width={30} height={30} />,
		title: 'Documentos',
		photoIds: [
			MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY,
			MediaId.DOCUMENTS_CEDULA,
			MediaId.DOCUMENTS_DRIVERS_LICENSE,
		],
		helpModal: (
			<div>
				<p>
					Toma las siguientes consideraciones a la hora de tomar las
					fotografías:
				</p>
				<ul>
					<li>Colocar el documento sobre una superficie plana.</li>
					<li>
						Contar con buena iluminación o habilita el flash del dispositivo.
					</li>
					<li>
						Tomar la foto lo más cerca posible{' '}
						<b>(debe verse el documento completo)</b>.
					</li>
					<li>La información en el documento debe ser legible</li>
				</ul>
			</div>
		),
	},
	[PhotoSectionId.VIDEO]: {
		icon: <VideoIcon className="theme-svg-icon" width={30} height={30} />,
		title: 'Video 360°',
		photoIds: [MediaId.VEHICLE_VIDEO],
		helpModal: (
			<div>
				<p>Toma las siguientes consideraciones a la hora de tomar el video:</p>
				<ul>
					<li>
						Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
					</li>
					<li>Debe verse el vehículo completo.</li>
					<li>El lugar donde realizas la inspección debe estar iluminado.</li>
				</ul>
			</div>
		),
	},
	[PhotoSectionId.COINSPECT_CRC]: {
		icon: <ExteriorIcon className="theme-svg-icon" width={30} height={30} />,
		title: 'Co-Inspecciones',
		photoIds: [
			MediaId.INSURED_VEHICLE_PANORAMIC_CRC,
			MediaId.INSURED_VEHICLE_LICENSE_CRC,
			MediaId.DOCUMENTS_CEDULA_CRC,
			MediaId.INSURED_VEHICLE_DOCUMENT_CRC,
			MediaId.INSURED_VEHICLE_RTV_CRC,
			MediaId.INSURED_CUSTOM_1_CRC,
			MediaId.INSURED_CUSTOM_2_CRC,
		],
		helpModal: (
			<div>
				<p>
					Toma las siguientes consideraciones a la hora de tomar las
					fotografías:
				</p>
				<ul>
					<li>
						Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
					</li>
					<li>Debe verse el vehículo completo.</li>
					<li>El lugar donde realizas la inspección debe estar iluminado.</li>
				</ul>
			</div>
		),
	},
	[PhotoSectionId.COINSPECT_PAN]: {
		icon: <ExteriorIcon className="theme-svg-icon" width={30} height={30} />,
		title: 'Co-Inspecciones',
		photoIds: [
			MediaId.INSURED_VEHICLE_PANORAMIC_PAN,
			MediaId.INSURED_VEHICLE_LICENSE_PAN,
			MediaId.INSURED_VEHICLE_DOCUMENT_PAN,
			MediaId.INSURED_CUSTOM_1_PAN,
			MediaId.INSURED_CUSTOM_2_PAN,
		],
		helpModal: (
			<div>
				<p>
					Toma las siguientes consideraciones a la hora de tomar las
					fotografías:
				</p>
				<ul>
					<li>
						Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
					</li>
					<li>Debe verse el vehículo completo.</li>
					<li>El lugar donde realizas la inspección debe estar iluminado.</li>
				</ul>
			</div>
		),
	},
};
